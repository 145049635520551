:root {
    --mainColor: #cc3333;
    --bodyColor: #fff;
    --accentColor: var(--mainColor);
    --accentColorHover: var(--mainColor);
    --buttonColor: #FFB347;
    --buttonHoverColor: #ec8a00;
    --iconColor: var(--mainColor);
    --headerColor: #fff;
    --headerTopColor: #f7f7f7;
    --menuFontColor: #fff;
    --headerTopFontColor: #737373;
    --footerColor: #ffff;
    --footerFontColor: #3d464d;
    --buttonFontColor: #fff;
    --faqColor: #fff;
}

@import "./fonts/stroyka/stroyka";
@import "./css/fontawesome/css/all.min.css";
@import "./css/bootstrap/css/bootstrap.min.css";
@import "./css/style";

.nav-links__item--has-submenu .nav-links__item-body{
    i{
        margin-left: 6px;
        font-size: 13px;
    }
}
.account-nav__item .nav-link.active {
    border-left: 2px solid var(--mainColor);
    color: #3d464d;
    font-weight: 500;
  }
.nav-links__item:hover{
    .nav-links__item-link {
        color: inherit;
    }
    .nav-links__item-body {
        background: rgba(255, 255, 255, 0.15);
        -webkit-box-shadow: none;
                box-shadow: none;
        -webkit-transition-duration: 0.1s;
                transition-duration: 0.1s;
    }
    .nav-links__item-arrow {
        fill: rgba(0, 0, 0, 0.25);
    }

    .nav-links__submenu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }

      .nav-links__submenu {
        opacity: 1;
        visibility: visible;
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
      }
}

.menu__item-link {
    i{
        position: absolute;
        top: calc(50% - 5px);
        color: #b3b3b3;
        right: 12px;
    }
}

.menu__item-link:hover {
    background: #f2f2f2;
}

.menu__item:hover{
    .menu__submenu {
        opacity: 1;
        visibility: visible;
        transform: rotateY(0deg);
    }
}

.menu__item:hover{
    .menu__submenu{
        display: flex;
        visibility: visible;
        opacity: 1;
        transform: rotateY(0deg);
        top: 0;
    }
      
}

.topbar-dropdown__btn{
    i{
        margin-left: 6px;
        font-size: 11px;
        color: #bfbfbf;
    }
}

.faq-area{
    background-color: #ffff;
    padding: 85px 0;

    h2{
        color: #131313;
        margin-bottom: 40px;
    }

    .accordion-item, .accordion-button{
        background-color: transparent;
    }

    .accordion-item{
        border-left:none ;
        border-right:none ;
    }
    .accordion-button{
        color: #131313;
        font-weight: 500;
        font-size: 20px;
        box-shadow: none;
    }
    .accordion-button:focus{
        border: none;
        box-shadow: none;
    }
}

.owl-carousel .owl-nav{
    position: absolute;
    top: -51px;
    display: flex;
    right: 0;
    gap: 3px;
}
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{
    width: 27px;
    height: 29px;
    padding: 0;
    margin: 0;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
    border-radius: 1.5px;
    background: var(--accentColor);
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
    color: #fff;
    font-size: 13px;

    &:focus {
        outline: none;
    }
    &:focus, &:hover {
        background: var(--accentColorHover);
        color: #fff;
    }
  
    &:active {
        -webkit-transition-duration: 0s;
                transition-duration: 0s;
        background: var(--mainColor);
        color: #fff;
    }
}

.block-products-carousel__slider .owl-carousel .owl-nav{
    top: -50px;
}
.block-products-carousel .block-header__groups-list{
    margin-right: 67px;
}
@media(max-width: 767px){
    .block-products-carousel__slider .owl-carousel .owl-nav{
        top: -88px;
    }
    .block-products-carousel .block-header__groups-list{
        margin-right: 0;
    }
}

.widgets{
    width: 100%;
    position: fixed;
    right: 20px;
    bottom:15px;
    height: 60px;
    z-index: 1000;
    .container{
        position: relative;
        width: 100%;
    }
}
.ss-icon {
    width: 60px; 
    height: 60px; 
    border-radius: 50%;
    background: var(--mainColor);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -130px;
    &.clicked #center-bar{
      background: #000;
      outline: none;
    }
    #center-bar{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--mainColor);
        position: relative;
        transition: .3s;
        z-index: 10;
        &:hover, &:focus {
            background: #333;
          }
          &:hover { 
            cursor: pointer;
          }
    }
    a { 
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: var(--accentColor);
        display:flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        transition: .4s;
        z-index: 9;
        &.whatsapp{
            background-color: #31D84F;
        }
        &.telegram{
            background-color: #23A9EA;
        }

        &:hover, &:focus {
        background-color: #000;
        }
    }
    
    &.clicked a:nth-child(2) { 
      transform:  translateX(-60px) ; 
    }
    &.clicked a:nth-child(3) { 
      transform:  translateX(-110px) ; 
    }
    &.clicked a:nth-child(4) { 
      transform:  translateX(-160px) ; 
    }
  }

  @media(max-width: 1400px){
    .widgets{

    
        .container{
            position: initial;
        }
    }
    .ss-icon {
        right: -2px;
    }
  }

.checkout-form{
    padding: 10px 30px;
}

.form-success{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: .3s;

    i{
        font-size: 40px;
        color: #31D84F;
    }
}

.form-visible{
    opacity: 1;
    visibility: visible;
}

.share-box{
    display: flex;
    justify-content: end;
    width: 100%;
}

.social-icons{
    display: flex;
    ul{
        display: flex;
        list-style: none;
        margin-bottom: 0;
        padding-left: 10px;

        li:not(:last-child){
            margin-right: 10px;
        }
    }
}

.form-main{
    position: relative;
    padding: 60px 0;
    display: flex;
    align-items: center;
    overflow: hidden;

    .form-back-img{
        position: absolute;
        right: 0;
        img{
            border-radius: 12px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media(max-width: 767px){
            display: none;
        }
    }

    .bottom-form{
        position: relative;
        z-index: 1;
        padding: 30px;
        background-color: var(--mainColor);
        color: #FFFFFF;

        .btn-secondary{
            background-color: var(--accentColor);
            border-color: var(--accentColor);
            color: #FFFFFF;

            &:hover{
                background-color: var(--accentColorHover);
                border-color: var(--accentColorHover);
            }
        }
        .form-success{
            background-color: var(--mainColor);

            i{
                color: #FFFFFF;
            }
        }
    }
}

.rewiev-card{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
    padding: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;


    .review-block{
        padding: 18px;
        display: flex;
        gap: 15px;

        img{
            width: 32px;
            height: 32px;
            object-fit: contain;
        }
    }

    .review-container{
        display: flex;
        flex-direction: column;
        color: #3D464D;
    }

    .review-title{
        font-weight: 500;
        font-size: 17px;
        margin-left: auto;
    }

    .review-wrapper{
        background-color: var(--mainColor);
        display: flex;
        gap: 8px;
        color: #fff;
        align-items: center;
        margin-top: auto;

        p{
            margin: 0;
            line-height: normal;
            font-size: 15px;
            margin-right: 12px;
        }

        img{
            width: 62px;
            height: 62px;
            object-fit: cover;
        }
    }
}

.top-socials{
    display: flex;

    a{
        padding: 0 8px;

        i{
            color: var(--iconColor);
        }
    }
}

.reviews{
    .owl-carousel .owl-item{
        min-height: 100%;
    }
    .owl-carousel .owl-stage{
        display: flex;
    }
}

.post-card {
    background-color: #fff;
    height: 100%;
    padding: 10px;
}

.post{
    background-color: #fff;
    padding: 20px;
}

.back-white{
    background-color: #fff;
    padding-top: 20px;
}
.block-posts__slider{
    .owl-carousel .owl-item{
        min-height: 100%;
    }
    .owl-carousel .owl-stage{
        display: flex;
    }
}

.btn-accent{
    background-color: var(--buttonColor);
    color: var(--buttonFontColor);
    border-radius: 20px;
    display: flex;
    gap: 10px;
    
    path{
        fill: var(--buttonFontColor);  
    }

    &:hover{
        background-color: var(--buttonHoverColor);
        color: var(--buttonFontColor);
    }

    &:active, &:first-child:active{
        box-shadow: none;
        background-color: var(--buttonHoverColor);
        border: none;
    }
}

.slogan{
    color: var(--mainColor);
    font-size: 16px;
}

@media screen and (min-width: 768px) {
    .nav-panel--sticky {
        position: fixed;
        top: 0;
        z-index: 999;
        width: 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    }
}

.btn-outline-cart{
    color: var(--mainColor);
    border-color: var(--mainColor);

    &:hover{
        background-color: var(--mainColor);
        color: #fff;
    }
}

.modal-header{
    border-bottom: none;
}

.product-wrapper{
    display: flex;
    margin-bottom: 20px;
    img{
        height: 80px;
        width: 80px;
        object-fit: cover;
        margin-right: 12px;
    }

    h4{
        font-size: 20px;
        color: #3D464D;
    }
}

.search-products{
    max-height: 320px;
    overflow-y: scroll;
    .search-products-item{
        display: flex;
        gap: 10px;
        margin-bottom: 15px;
        color: #3d464d;

        .search-wrapper{
            display: flex;
            flex-direction: column;
        }
        img{
            width: 70px;
            height: 70px;
            object-fit: cover;
        }
        .title{
            font-weight: bold;
        }
        .price{
            margin-top: auto;
        }
        .subtitle{
            font-size: 14px;
        }
    }
}

.suggestions--location--mobile-header{
    max-height: 350px;
}
.suggestions--location--indicator{
    max-height: 335px;
    overflow-y: auto;
}

@media(max-width: 767px){
    .suggestions--location--mobile-header{
        max-height: 250px;
    }
}

.advantages2{
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0;
    margin: 80px 0 100px;
    .advantages2-item{
        position: relative;
        height: 195px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
        }

        .text{
            position: relative;
            z-index: 1;
            color: #fff;
            text-align: center;
            h4{
                font-size: 23px;
            }
            
        }
    }
}

@media(max-width: 767px){
    .advantages2{
        padding: 60px 0;
        margin: 70px 0 60px;
    }
}
.block-header__groups-list{
    padding-bottom: 4px;
    &::-webkit-scrollbar {
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }
}
.filter-categories-alt__children{
    margin: 0 -20px;
}

.filter-categories-alt__item > .filter-categories-alt__children:before{
    height: 12px;
}

.mobile-header__menu-button{
    display: none;
}

@media(max-width: 1024px){
    .product-tabs__content{
        padding: 0;
    }
}

@media(max-width: 992px){
    .site-header{
        .nav-panel__nav-links{
            display: none;
        }
        .mobile-header__menu-button{
            display: flex;
        }
        
    }
}
@media(max-width: 767px){
    .site-header{
        .site-header__topbar, .site-header__middle, .nav-panel__nav-links{
            display: none;
        }
        
    }
}
.isLoading {
    pointer-events: none;
    opacity: .5;
    position: relative;
    min-height: 50px;
  
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid var(--mainColor);
      border-left-color: transparent;
      animation: rotate .5s infinite linear;
      z-index: 99;
    }
  
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
}

#root{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.site__footer{
    margin-top: auto;
}
.block-products-carousel__slider{
    .product-card__buttons{
        .btn{
            margin-top: 0;
        }
    }
}